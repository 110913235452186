import React from "react"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import FullWidthImage from "../components/full-width-image"
import SEO from "../components/seo"
import ContentWrapper from "../components/content-wrapper"

const PrivacyPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO page="privacy" />
    <FullWidthImage />
    <ContentWrapper>
      <h1>
        <FormattedMessage id="privacy" />
      </h1>
      <h2>
        <FormattedMessage id="copyright_headline" />
      </h2>
      <p>
        <FormattedMessage id="copyright_text" />
      </p>
      <h2>
        <FormattedMessage id="disclaimer_headline" />
      </h2>
      <p>
        <FormattedMessage id="disclaimer_text" />
      </p>
    </ContentWrapper>
  </Layout>
)

export default PrivacyPage
